/* Copyright Qualisure Diagnostics, Inc., 2021
All Rights Reserved
UNPUBLISHED, LICENSED SOFTWARE.

CONFIDENTIAL AND PROPRIETARY INFORMATION
WHICH IS THE PROPERTY OF QUALISURE DIAGNOSTICS, INC.
This page is the main parent page the houses the dashboard
*/

import { getAuthenticatedAxios } from './util'
import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Navbar from './components/layout/Navbar'
import Dashboard from './components/dashboard/Dashboard'
import { Link } from 'react-router-dom'
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";

function App(props) {
  const [Id, setLabID] = useState('812')
  const [menu, setMenu] = useState(false)
  const [version, setVersion] = useState('')

  useEffect(async () => {
    const axios = await getAuthenticatedAxios();

    await axios.get(`/api/getVersion`)
      .then((response) => {
        // console.log(response)
        setVersion(response.data.envioronments[0].version)
      })
  });


  const signOut = (instance) => {
    console.log("signOut")
    instance.logoutRedirect({
      onRedirectNavigate: () => !BrowserUtils.isInIframe()
    })
  }
  // onClick={onClick}
  const { instance } = useMsal();
  return (
    <BrowserRouter>
      <div style={window.innerWidth > 750 ? { width: "100%", height: "100%", padding: "0px 20px 20px 20px" } : { width: "100%", height: "100%", padding: "0px 20px 20px 20px" }}>
        <div >
          {Id ? <div style={{ background: "white", position: "fixed", zIndex: "888", width: "100%", paddingTop: "13px", marginTop: "-34px", marginLeft: "-20px", paddingLeft: "20px", boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}>

            {Id ? <button className="themeButton" style={{ position: 'fixed', right: 12, marginTop: "4px" }} onClick={() => signOut(instance)}>Log Out</button> : null}
            <Link to='/' style={{ display: "flex", justifyContent: "center", paddingBottom: "10px", marginLeft: "-71px" }}><img alt="doubleEagle" src='https://qualisuredx.com/wp-content/uploads/2019/10/qualisure-logo.png' width={230} height={40} /></Link>
            {menu ? <Navbar /> : null}
          </div> : null}
          <Switch>
            <Route exact path='/' component={Dashboard} />
          </Switch>
        </div>
        <div className='versionBanner'>
          <p><span className='versionP'>Version: </span>{version}</p>
        </div>

      </div>
    </BrowserRouter>
  );
}

export default App;
