/* Copyright Qualisure Diagnostics, Inc., 2021
All Rights Reserved
UNPUBLISHED, LICENSED SOFTWARE.

CONFIDENTIAL AND PROPRIETARY INFORMATION
WHICH IS THE PROPERTY OF QUALISURE DIAGNOSTICS, INC.

This page is the you view results of the system
*/

import axios from "axios";
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { getAuthenticatedAxios } from "../../util";

const columnStyle = {
  textAlign: "center",
  color: "#2b388f",
  cursor: "pointer",
};

const dateFields = ["PatientDateofBirth", "ReportDate"];

class ResultsPopup extends React.Component {
  state = {
    orderByProperty: "ResultsKey",
    orderByDirection: "desc",
    uploadedFile: [],
    patientResults: [],
    Reports: [],
    sortBy: {
      // at initial load data is sorted by "orderByProperty". so seting 'desc' or 'asc' here has no impact on very first load.
      PatientDateofBirth: "desc",
      ReportDate: "desc",
      AccessionID: "desc",
      FileLinkID: "desc",
      Filename: "desc",
      LabID: "desc",
      PatientFirstName: "desc",
      PatientSex: "desc",
      ResultID: "desc",
    },
    activeSortField: "",
  };
  componentDidMount = async () => {
    const axios = await getAuthenticatedAxios();
    await axios.get(`/api/matches`).then((response) => {
      this.setState({
        Reports: this.initialSorting(
          response.data.Reports,
          this.state.orderByProperty,
          this.state.orderByDirection
        ),
        activeSortField: this.state.orderByProperty,
        sortBy: {
          ...this.state.sortBy,
          [this.state.orderByProperty]: this.state.orderByDirection,
        },
      });
    });
  };

  initialSorting = (dataSet = [], fieldName, sortOrder = "desc") => {
    // sort by date
    if (dateFields.includes(this.state.orderByProperty)) {
      return this.sortByDate(dataSet, fieldName, sortOrder);
    }
    // numeric sorting
    if (this.state.orderByProperty === "ResultsKey") {
      console.log("result key sorting");
      return dataSet.sort((a, b) => b.ResultsKey - a.ResultsKey);
    }

    // natural sorting
    return this.naturalSorting(dataSet, fieldName, sortOrder);
  };

  openLink = async (filename, LabID) => {
    var tempOrders = {
      file: filename,
      labID: LabID,
    };
    const axios = await getAuthenticatedAxios();
    await axios
      .post("/api/getPDFReport", tempOrders)
      .then(async (result) => {
        // console.log(result.data.sasUrl)
        window.open(result.data.sasUrl, "_blank");
      })
      .catch((err) => {
        alert("There was an error!: " + err);
        return;
      });
  };

  onOrderByProperty = (fieldName) => {
    if (!this.state.Reports.length) return;
    const sortBy = { ...this.state.sortBy };
    const sortOrder = sortBy[fieldName] === "desc" ? "asc" : "desc";
    // reset others field to 'desc'
    Object.keys(sortBy)
      .filter((field) => field !== fieldName) // exclude current sort field
      .forEach((key) => (sortBy[key] = "desc"));

    this.setState(
      (prev) => ({
        ...prev,
        sortBy: {
          ...sortBy,
          [fieldName]: sortOrder,
        },
        activeSortField: fieldName,
      }),
      () => {
        if (["PatientDateofBirth", "ReportDate"].includes(fieldName)) {
          this.handleSortByDate(fieldName, sortOrder);
          return;
        }
        //
        this.handleSortByAlphaNumeric(fieldName, sortOrder);
      }
    );
  };

  handleSortByDate = (fieldName, sortOrder = "desc") => {
    const sortedReports = this.sortByDate(
      this.state.Reports,
      fieldName,
      sortOrder
    );
    this.setState((prev) => ({
      ...prev,
      Reports: sortedReports,
    }));
  };

  handleSortByAlphaNumeric = (fieldName, sortOrder = "desc") => {
    const sortedReports = this.naturalSorting(
      this.state.Reports,
      fieldName,
      sortOrder
    );

    this.setState((prev) => ({
      ...prev,
      Reports: sortedReports,
    }));
  };

  // natural sorting
  naturalSorting = (dataSet = [], fieldName, sortOrder = "desc") => {
    if (sortOrder === "desc") {
      return dataSet.sort((a, b) =>
        b[fieldName].localeCompare(a[fieldName], "en", { sensitivity: "base" })
      );
    }
    // sort asc

    return dataSet.sort((a, b) =>
      a[fieldName].localeCompare(b[fieldName], "en", { sensitivity: "base" })
    );
  };

  // sort numeric
  sortByDate = (dataSet = [], fieldName, sortOrder = "desc") => {
    if (sortOrder === "desc") {
      return dataSet.sort(
        (a, b) => new Date(b[fieldName]) - new Date(a[fieldName])
      );
    }
    // sort asc

    return dataSet.sort(
      (a, b) => new Date(a[fieldName]) - new Date(b[fieldName])
    );
  };

  render() {
    if (this.state.Reports) {
      return (
        <div className="popup" style={{ overflow: "scroll", lineHeight: 1.2 }}>
          <div
            className="popup_inner"
            style={{ top: "8%", right: "8%", left: "8%" }}
          >
            <div className="card card2" style={{ backgroundColor: "#f4f2f2" }}>
              <h1
                className="Titleidheading secondaryColor"
                style={{ margin: 0, borderBottom: "2px solid #ccc" }}
              >
                {this.props.text}
              </h1>

              <i
                id="x"
                className="material-icons"
                onClick={this.props.closePopup}
                style={{
                  height: "30px",
                  paddingTop: "15px",
                  lineHeight: "0",
                  top: "3px",
                }}
              >
                close
              </i>

              <div>
                <Tabs style={{ paddingTop: "24px", display: "grid" }}>
                  <TabList
                    style={{
                      marginBottom: "0px !important",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <Tab
                      style={{
                        color: "#2b388f",
                        border: "1px solid #ccc",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        borderBottom: "none",
                      }}
                    >
                      Reports
                    </Tab>
                  </TabList>
                  <TabPanel
                    style={{
                      borderLeft: "1px solid #ccc",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    <div>
                      <div
                        className="table1"
                        style={{ overflow: "auto", maxHeight: "400px" }}
                      >
                        <table>
                          <tbody>
                            <tr>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() => {
                                  this.onOrderByProperty("ReportDate");
                                }}
                                style={columnStyle}
                              >
                                Report Date{" "}
                                <SortOrder
                                  isActive={
                                    this.state.activeSortField === "ReportDate"
                                  }
                                  order={this.state.sortBy["ReportDate"]}
                                />
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() => this.onOrderByProperty("LabID")}
                                style={columnStyle}
                              >
                                LabID{" "}
                                <SortOrder
                                  isActive={
                                    this.state.activeSortField === "LabID"
                                  }
                                  order={this.state.sortBy["LabID"]}
                                />
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() => {
                                  this.onOrderByProperty("FileLinkID");
                                }}
                                style={columnStyle}
                              >
                                FileLinkID{" "}
                                <SortOrder
                                  isActive={
                                    this.state.activeSortField === "FileLinkID"
                                  }
                                  order={this.state.sortBy["FileLinkID"]}
                                />
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() => {
                                  this.onOrderByProperty("PatientFirstName");
                                }}
                                style={columnStyle}
                              >
                                Name{" "}
                                <SortOrder
                                  isActive={
                                    this.state.activeSortField ===
                                    "PatientFirstName"
                                  }
                                  order={this.state.sortBy["PatientFirstName"]}
                                />
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() => {
                                  this.onOrderByProperty("PatientSex");
                                }}
                                style={columnStyle}
                              >
                                Sex{" "}
                                <SortOrder
                                  isActive={
                                    this.state.activeSortField === "PatientSex"
                                  }
                                  order={this.state.sortBy["PatientSex"]}
                                />
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() =>
                                  this.onOrderByProperty("PatientDateofBirth")
                                }
                                style={columnStyle}
                              >
                                DOB{" "}
                                <SortOrder
                                  isActive={
                                    this.state.activeSortField ===
                                    "PatientDateofBirth"
                                  }
                                  order={
                                    this.state.sortBy["PatientDateofBirth"]
                                  }
                                />
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() => {
                                  this.onOrderByProperty("AccessionID");
                                }}
                                style={columnStyle}
                              >
                                Accession #{" "}
                                <SortOrder
                                  isActive={
                                    this.state.activeSortField === "AccessionID"
                                  }
                                  order={this.state.sortBy["AccessionID"]}
                                />
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() => {
                                  this.onOrderByProperty("ResultID");
                                }}
                                style={columnStyle}
                              >
                                ResultID{" "}
                                <SortOrder
                                  isActive={
                                    this.state.activeSortField === "ResultID"
                                  }
                                  order={this.state.sortBy["ResultID"]}
                                />
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() => {
                                  this.onOrderByProperty("Filename");
                                }}
                                style={columnStyle}
                              >
                                Report
                              </th>
                            </tr>

                            {this.state.Reports &&
                              this.state.Reports.map((x) => (
                                <tr
                                  className="rows"
                                  key={x.ResultsKey}
                                  style={{ background: "#f3f3f3" }}
                                >
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.ReportDate}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.LabID}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.FileLinkID}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.PatientFirstName +
                                      " " +
                                      x.PatientLastName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.PatientSex}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.PatientDateofBirth}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.AccessionID}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.ResultID}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                    onClick={() =>
                                      this.openLink(x.Filename, x.LabID)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                      <polyline points="13 2 13 9 20 9"></polyline>
                                    </svg>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="lds-roller" style={{ marginTop: "17%" }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }
  }
}

export default ResultsPopup;

function SortOrder({ order = "asc", isActive = false }) {
  const arrowIcon = order === "asc" ? <> &#9650;</> : <>&#9660;</>;
  return isActive ? (
    <span>{arrowIcon}</span>
  ) : (
    <span style={{ opacity: 0.5 }}>{arrowIcon}</span>
  );
}
