/* Copyright Qualisure Diagnostics, Inc., 2021
All Rights Reserved
UNPUBLISHED, LICENSED SOFTWARE.

CONFIDENTIAL AND PROPRIETARY INFORMATION
WHICH IS THE PROPERTY OF QUALISURE DIAGNOSTICS, INC.

This is the display tile for the results.
*/

import React, { Component } from 'react'
import ResultsPopup from './ResultsPopup'

class ResultsTile extends Component {
    state = {
        openForm: false
    }
    OpenProjectForm = () => {
        this.setState({
            openForm: !this.state.openForm
        })
    }
    render() {
        return (
            <div className="section sectionwidth" >
                 {this.state.openForm ?
                    <ResultsPopup
                        EmployeeSubmittedId={this.props.EmployeeID}
                        text='View Reports'
                        closePopup={this.OpenProjectForm.bind(this)}
                    /> : null}
                
                <div>
                    <div className="card z-depth-0" onClick={() => this.OpenProjectForm()} style={{cursor: "pointer"}}>
                        <div className="card-content roundedcorner">
                            <div className="appdisplay">
                                <svg height="24pt" viewBox="0 0 511 511.99775" width="24pt" fill="#EB6A26" xmlns="http://www.w3.org/2000/svg"><path d="m501.425781 238.109375-82.222656-41.109375 82.222656-41.113281c14.707031-7.351563 14.722657-28.414063 0-35.777344l-236-118c-5.632812-2.8125-12.257812-2.8125-17.890625 0l-236 118c-14.707031 7.355469-14.722656 28.417969 0 35.777344l82.222656 41.113281-82.222656 41.109375c-14.707031 7.355469-14.722656 28.414063 0 35.777344l82.222656 41.113281-82.222656 41.109375c-14.707031 7.355469-14.722656 28.414063 0 35.777344l236 118c5.621094 2.808593 12.246094 2.820312 17.890625 0l236-118c14.707031-7.355469 14.722657-28.414063 0-35.777344l-82.222656-41.109375 82.222656-41.113281c14.707031-7.351563 14.722657-28.414063 0-35.777344zm-244.945312-195.75 191.277343 95.640625-191.277343 95.636719-191.277344-95.636719zm191.277343 331.640625-191.277343 95.636719-191.277344-95.636719 73.277344-36.640625 109.054687 54.527344c5.625 2.808593 12.246094 2.820312 17.890625 0l109.054688-54.527344zm-191.277343-22.363281-191.277344-95.636719 73.277344-36.640625 109.054687 54.527344c5.625 2.808593 12.246094 2.820312 17.890625 0l109.054688-54.527344 73.277343 36.640625zm0 0"/></svg>
                                
                                <span className="card-title secondaryColor" style={{paddingLeft: "4px"}}>Results</span>
                            </div>
                            <p className="secondaryColor">View Reports</p>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default ResultsTile