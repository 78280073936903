import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
// import { signOut } from '../../store/actions/authActions'

class SignedOutLinks extends Component {
  componentDidMount = async () => {
    window.addEventListener('resize', this.resize)

  }
  resize = () => this.forceUpdate()
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  render() {
    if (window.innerWidth > 900) {
      return (
        <div>
          <ul style={{ display: "flex", margin: "0 auto", flexDirection: "column" }}>
            <li><NavLink to='/' style={{ fontSize: "1vw" }}>Home</NavLink></li>
            {/* <li><NavLink to='/Register' style={{ fontSize: "1vw" }}>Register</NavLink></li> */}
            {/* <li><NavLink to='/Upcoming_Events' style={{fontSize: "1vw"}}>Upcoming Events</NavLink></li> */}
            {/* <li><NavLink to='/News' style={{ fontSize: "1vw" }}>{"News & Upcoming Events"}</NavLink></li>
            <li><NavLink to='/Terms_Of_Access' style={{ fontSize: "1vw"}}>Terms of Access</NavLink></li>
            <li><NavLink to='/Contact' style={{ fontSize: "1vw"}}>Contact</NavLink></li> */}
            {/* <li><a className="colorChange" onClick={this.props.signOut}>Log Out</a></li> */}
            {/* <li><NavLink to='/signup'>Signup</NavLink></li> */}
            {/* <li><NavLink to='/signin'>Login</NavLink></li> */}
          </ul>
        </div>
      )
    } else {
      return (
        <div>
          <div class="dropdown">
            <button class="dropbtn fa" style={window.innerWidth > 600 && window.innerWidth < 900 ? {fontSize: "1.5em", marginTop: "17%"} : {fontSize: "1.5em", marginTop: "3%"}}>&#xf0c9;</button>
            <div class="dropdown-content1">
              <NavLink to='/' style={{ fontSize: "1.5vh" }}>Home</NavLink>
              {/* <NavLink to='/Register' style={{ fontSize: "1.5vh" }}>Register</NavLink> */}
              {/* <NavLink to='/Upcoming_Events' style={{fontSize: "1.5vh"}}>Upcoming Events</NavLink> */}
              {/* <NavLink to='/News' style={{ fontSize: "1.5vh" }}>{"News & Upcoming Events"}</NavLink>
              <NavLink to='/Terms_Of_Access' style={{ fontSize: "1.5vh" }}>Terms of Access</NavLink>
              <NavLink to='/Contact' style={{ fontSize: "1.5vh" }}>Contact</NavLink> */}
            </div>
          </div>
        </div>
      )
    }

  }
}

export default SignedOutLinks