import { PublicClientApplication } from "@azure/msal-browser";

const configuration = {
    auth: {
        clientId: "15bea1d4-ef73-4f2c-8869-5a637c6dcbdc",
        authority: "https://login.microsoftonline.com/qualisuredx.com"
    }
  };
  
  const pca = new PublicClientApplication(configuration);

  export default pca;