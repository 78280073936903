/* Copyright Qualisure Diagnostics, Inc., 2021
All Rights Reserved
UNPUBLISHED, LICENSED SOFTWARE.

CONFIDENTIAL AND PROPRIETARY INFORMATION
WHICH IS THE PROPERTY OF QUALISURE DIAGNOSTICS, INC.

This is the display tile for the import screen
*/

import React, { Component } from 'react'
import ImportOrdersForm from './ImportOrdersForm'

class OrdersTile extends Component {
    state = {
        openForm: false
    }
    OpenOrdersForm = () => {
        this.setState({
            openForm: !this.state.openForm
        })
    }
    render() {
        return (
            <div className="section sectionwidth" >
                {this.state.openForm ?
                    <ImportOrdersForm
                        EmployeeSubmittedId={this.props.EmployeeID}
                        text='Upload Order and Gene Expression File'
                        closePopup={this.OpenOrdersForm.bind(this)}
                    /> : null}
                
                <a >
                    <div className="card z-depth-0" onClick={() => this.OpenOrdersForm()} style={{cursor: "pointer"}}>
                        <div className="card-content roundedcorner">
                            <div className="appdisplay">
                                <i className="fa fa-clipboard" style={{fontSize: "xx-large"}}></i>
                                <span className="card-title secondaryColor" style={{paddingLeft: "4px"}}>Import JSON File</span>
                            </div>
                            <p className="secondaryColor">Add an Orders and Gene Matrix for Processing</p>
                        </div>
                    </div>
                </a>
            </div>

        )
    }
}

export default OrdersTile
