/* Copyright Qualisure Diagnostics, Inc., 2021
All Rights Reserved
UNPUBLISHED, LICENSED SOFTWARE.

CONFIDENTIAL AND PROPRIETARY INFORMATION
WHICH IS THE PROPERTY OF QUALISURE DIAGNOSTICS, INC.

This page is where you import orders and Gene matrix for the system
*/

import axios from "axios";
import React from "react";
import { format as formatDate, isValid, addMinutes } from "date-fns";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { orderBy } from "lodash";
import moment from "moment";
import closeIcon from "../images/times-circle-solid.svg";
import { getAuthenticatedAxios } from "../../util";

class ImportOrdersForm extends React.Component {
  state = {
    orderByProperty: "StateCode",
    orderByDirection: "asc",
    uploadedFile: [],
    uploadedFile2: [],
    Well: {
      ...this.props.well,
    },
    options: [
      { id: 1, StateCode: "AL", name: "Alabama" },
      { id: 2, StateCode: "AK", name: "Alaska" },
      { id: 3, StateCode: "AZ", name: "Arizona" },
      { id: 4, StateCode: "AR", name: "Arkansas" },
      { id: 5, StateCode: "CA", name: "California" },
      { id: 6, StateCode: "CO", name: "Colorado" },
      { id: 7, StateCode: "CT", name: "Connecticut" },
      { id: 8, StateCode: "DE", name: "Delaware" },
      { id: 9, StateCode: "DC", name: "District of Columbia" },
      { id: 10, StateCode: "FL", name: "Florida" },
      { id: 11, StateCode: "GA", name: "Georgia" },
      { id: 12, StateCode: "HI", name: "Hawaii" },
      { id: 13, StateCode: "ID", name: "Idaho" },
      { id: 14, StateCode: "IL", name: "Illinois" },
      { id: 15, StateCode: "IN", name: "Indiana" },
      { id: 16, StateCode: "IA", name: "Iowa" },
      { id: 17, StateCode: "KS", name: "Kansas" },
      { id: 18, StateCode: "KY", name: "Kentucky" },
      { id: 19, StateCode: "LA", name: "Louisiana" },
      { id: 20, StateCode: "ME", name: "Maine" },
      { id: 21, StateCode: "MD", name: "Maryland" },
      { id: 22, StateCode: "MA", name: "Massachusetts" },
      { id: 23, StateCode: "MI", name: "Michigan" },
      { id: 24, StateCode: "MN", name: "Minnesota" },
      { id: 25, StateCode: "MS", name: "Mississippi" },
      { id: 26, StateCode: "MO", name: "Missouri" },
      { id: 27, StateCode: "MT", name: "Montana" },
      { id: 28, StateCode: "NE", name: "Nebraska" },
      { id: 29, StateCode: "NV", name: "Nevada" },
      { id: 30, StateCode: "NH", name: "New Hampshire" },
      { id: 31, StateCode: "NJ", name: "New Jersey" },
      { id: 32, StateCode: "NM", name: "New Mexico" },
      { id: 33, StateCode: "NY", name: "New York" },
      { id: 34, StateCode: "NC", name: "North Carolina" },
      { id: 35, StateCode: "ND", name: "North Dakota" },
      { id: 36, StateCode: "OH", name: "Ohio" },
      { id: 37, StateCode: "OK", name: "Oklahoma" },
      { id: 38, StateCode: "OR", name: "Oregon" },
      { id: 39, StateCode: "PA", name: "Pennsylvania" },
      { id: 40, StateCode: "PR", name: "Puerto Rico" },
      { id: 41, StateCode: "RI", name: "Rhode Island" },
      { id: 42, StateCode: "SC", name: "South Carolina" },
      { id: 43, StateCode: "SD", name: "South Dakota" },
      { id: 44, StateCode: "TN", name: "Tennessee" },
      { id: 45, StateCode: "TX", name: "Texas" },
      { id: 46, StateCode: "UT", name: "Utah" },
      { id: 47, StateCode: "VT", name: "Vermont" },
      { id: 48, StateCode: "VA", name: "Virginia" },
      { id: 49, StateCode: "WA", name: "Washington" },
      { id: 50, StateCode: "WV", name: "West Virginia" },
      { id: 51, StateCode: "WI", name: "Wisconsin" },
      { id: 52, StateCode: "WY", name: "Wyoming" },
    ],
  };
  componentDidMount = async () => {};

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    var newArray = [];
    for (var i = 0; i < this.state.matchedArray.length; i++) {
      newArray.push({
        order:
          this.state.OrdersFile[this.state.matchedArray[i].OrdersFileIndex],
        GeneMatrix:
          this.state.GeneFile[this.state.matchedArray[i].GeneFileIndex],
      });
    }

    for (var j = 0; j < newArray.length; j++) {
      const axios = await getAuthenticatedAxios();
      var tempOrders = newArray[j];
      await axios.post('/api/insertOrders', tempOrders)
          .then(async (result) => {
              console.log(result)

          }).catch((err) => {
              alert("There was an error!: " + err)
              return
          });
    }
    const data = new FormData();
    data.append("myFiles", this.state.uploadedFile[0].file);
    data.append("myFiles", this.state.uploadedFile2[0].file);
    const axios = await getAuthenticatedAxios();
    await axios.post(`/api/addFilesToAzure/${this.state.OrdersFile[0].labId}`, data)
        .then(async (result) => {
            console.log(result)

        }).catch((err) => {
            alert("There was an error!: " + err)
            return
        });

    window.location.reload();
    return;
  };

  grabFile2 = async (fileType, e) => {
    const { id } = e.target;
    console.log(e.target.files[0]);
    this.setState({ errMessage: null });
    let file = e.target.files[0];
    // let reader = new FileReader();

    // var input = e.event.target;

    var reader = new FileReader();
    reader.onload = async () => {
      var text = reader.result;
      // var node = document.getElementById('output');
      // node.innerText = text;

      var orders = JSON.parse(text);

      
      var newOrdersArray = [];
      for (var k = 0; k < orders.length; k++) {
          var ordersKeys = Object.keys(orders[k]);
        var newOrders = {};
        for (var i = 0; i < ordersKeys.length; i++) {
          if (
            ordersKeys[i] === "version" ||
            ordersKeys[i] === "labId" ||
            ordersKeys[i] === "fileLinkId"
          ) {
            newOrders = {
              ...newOrders,
              [ordersKeys[i]]: orders[k][ordersKeys[i]],
            };
          } else {
            var secondLevelKeys = Object.keys(orders[k][ordersKeys[i]]);
            for (var j = 0; j < secondLevelKeys.length; j++) {
              newOrders = {
                ...newOrders,
                [ordersKeys[i] + secondLevelKeys[j]]:
                  orders[k][ordersKeys[i]][secondLevelKeys[j]],
              };
            }
          }
        }
        newOrdersArray.push({
          ...newOrders,
          ReceivedDateTime: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss.SSS"),
        });
      }


      this.setState({
        OrdersFile: newOrdersArray,
      });
    };
    reader.readAsText(file);

    var index = this.state.uploadedFile.length
      ? this.state.uploadedFile.length
      : 0;
    this.setState({
      hasUploadedFile: true,
      uploadedFile: [
        {
          type: id,
          id: id + index,
          file: e.target.files[0],
          LinkType: fileType,
        },
      ],
    });
  };
  removeFile2 = (id, x, index) => {
    document.getElementById("ParcelFile1").value = null;
    this.setState({
      uploadedFile: this.state.uploadedFile.filter((y) => y.id !== x.id),
      OrdersFile: null,
    });
  };

  grabFile = async (fileType, e) => {
    const { id } = e.target;
    this.setState({ BulkParcelUpload: null, results: null });
    console.log(e.target.files[0]);
    this.setState({ errMessage: null });
    let file = e.target.files[0];
    // let reader = new FileReader();

    // var input = e.event.target;

    var reader = new FileReader();
    reader.onload = async () => {
      var text = reader.result;
      // var node = document.getElementById('output');
      // node.innerText = text;

      var Gene = JSON.parse(text);
      Gene = Gene.test_data;
      var GeneKeys = Object.keys(Gene[0]);
      console.log(GeneKeys);
      var FinalGeneArray = [];
      for (var j = 0; j < Gene.length; j++) {
        var tempVarible = {};
        var tempObject = {};
        for (var i = 0; i < GeneKeys.length; i++) {
          if (GeneKeys[i] === "labID") {
            tempObject = {
              ...tempObject,
              labID: Gene[j][GeneKeys[i]],
            };
          } else if (GeneKeys[i] === "QC_lab_status") {
            tempObject = {
              ...tempObject,
              QC_lab_status: Gene[j][GeneKeys[i]],
            };
          } else if (GeneKeys[i] === "QC_lab_error_code") {
            tempObject = {
              ...tempObject,
              QC_lab_error_code: Gene[j][GeneKeys[i]],
            };
          } else if (GeneKeys[i] === "SMconverter_version") {
            tempObject = {
              ...tempObject,
              SMconverter_version: Gene[j][GeneKeys[i]],
            };
          } else if (GeneKeys[i] === "fileLinkId") {
            tempObject = {
              ...tempObject,
              FileLinkID: Gene[j][GeneKeys[i]],
            };
          } else {
            tempVarible = {
              ...tempVarible,
              [[GeneKeys[i]]]: Gene[j][GeneKeys[i]],
            };
          }
        }
        FinalGeneArray.push({ ...tempObject, Variables: tempVarible });
      }
      console.log(FinalGeneArray);
      this.setState({
        GeneFile: FinalGeneArray,
      });
    };
    reader.readAsText(file);

    var index = this.state.uploadedFile2.length
      ? this.state.uploadedFile2.length
      : 0;
    this.setState({
      hasUploadedFile: true,
      uploadedFile2: [
        {
          type: id,
          id: id + index,
          file: e.target.files[0],
          LinkType: fileType,
        },
      ],
    });
  };
  removeFile = (id, x, index) => {
    document.getElementById("ParcelFile2").value = null;
    this.setState({
      uploadedFile2: this.state.uploadedFile2.filter((y) => y.id !== x.id),
      GeneFile: null,
      matchedArray: null,
      hasUploadedFile: false,
      buttonPressed: false,
      OrdersUnMatched: null,
      GeneUnMatched: null,
      dateErrorMeaage: null,
      verification: false,
      errorArray: null,
    });
  };

  verificationCheck = () => {
    var ArrayOfCodes = [];
    var flag = true;
    var dateErrorMeaage = null;
    for (var jj = 0; jj < this.state.OrdersFile.length; jj++) {
      if (
        !this.state.OrdersFile[jj].fileLinkId ||
        /^\s*$/.test(this.state.OrdersFile[jj].fileLinkId)
      ) {
        ArrayOfCodes.push("FileLinkID");
        flag = false;
      }
      if (
        !this.state.OrdersFile[jj].labId ||
        /^\s*$/.test(this.state.OrdersFile[jj].labId)
      ) {
        ArrayOfCodes.push("LabID");
        flag = false;
      }
      if (
        !this.state.OrdersFile[jj].patientsex ||
        /^\s*$/.test(this.state.OrdersFile[jj].patientsex)
      ) {
        ArrayOfCodes.push("PatientSex");
        flag = false;
      }
 
      if (
        !this.state.OrdersFile[jj].patientdateOfBirth ||
        /^\s*$/.test(this.state.OrdersFile[jj].patientdateOfBirth)
      ) {
        ArrayOfCodes.push("Date of Birth");
        flag = false;
      } else if (
        this.state.OrdersFile[jj].patientdateOfBirth &&
        !moment(
          this.state.OrdersFile[jj].patientdateOfBirth,
          "YYYY-MM-DD",
          true
        ).isValid()
      ) {
        dateErrorMeaage = `${this.state.OrdersFile[jj].patientdateOfBirth} is not a valid date`;
        flag = false;
      }
      if (
        !this.state.OrdersFile[jj].patientfirstName ||
        /^\s*$/.test(this.state.OrdersFile[jj].patientfirstName)
      ) {
        ArrayOfCodes.push("First Name");
        flag = false;
      }
      if (
        !this.state.OrdersFile[jj].patientlastName ||
        /^\s*$/.test(this.state.OrdersFile[jj].patientlastName)
      ) {
        ArrayOfCodes.push("Last Name");
        flag = false;
      }
      if (
        !this.state.OrdersFile[jj].specimencollectionDate ||
        /^\s*$/.test(this.state.OrdersFile[jj].specimencollectionDate)
      ) {
        ArrayOfCodes.push("Patient Collection Date");
        flag = false;
      } else if (
        this.state.OrdersFile[jj].specimencollectionDate &&
        !moment(
          this.state.OrdersFile[jj].specimencollectionDate,
          "YYYY-MM-DD",
          true
        ).isValid()
      ) {
        dateErrorMeaage = `${this.state.OrdersFile[jj].specimencollectionDate} is not a valid date`;
        flag = false;
      }
      if (
        !this.state.OrdersFile[jj].testtestDate ||
        /^\s*$/.test(this.state.OrdersFile[jj].testtestDate)
      ) {
        ArrayOfCodes.push("Test Date");
        flag = false;
      } else if (
        this.state.OrdersFile[jj].testtestDate &&
        !moment(
          this.state.OrdersFile[jj].testtestDate,
          "YYYY-MM-DD",
          true
        ).isValid()
      ) {
        dateErrorMeaage = `${this.state.OrdersFile[jj].testtestDate} is not a valid date`;
        flag = false;
      }

      if (
        !this.state.OrdersFile[jj].testtestRequested ||
        /^\s*$/.test(this.state.OrdersFile[jj].testtestRequested)
      ) {
        ArrayOfCodes.push("Test Requested");
        flag = false;
      }
    }
    ArrayOfCodes = [...new Set(ArrayOfCodes)];
    var errorArray =
      ArrayOfCodes.length !== 0
        ? `These Fields are not present: ${ArrayOfCodes.join(", ")}`
        : null;
    var matchedArray = [];
    for (var i = 0; i < this.state.GeneFile.length; i++) {
      for (var jj = 0; jj < this.state.OrdersFile.length; jj++) {
        if (
          this.state.OrdersFile[jj].fileLinkId ===
            this.state.GeneFile[i].FileLinkID &&
          this.state.OrdersFile[jj].labId === this.state.GeneFile[i].labID
        ) {
          matchedArray.push({ OrdersFileIndex: jj, GeneFileIndex: i });
        }
      }
    }
    var OrdersIndexArray = matchedArray.map((x) => x.OrdersFileIndex);
    var OrdersUnMatched = this.state.OrdersFile.filter(
      (x, index) => !OrdersIndexArray.includes(index)
    );

    var GeneIndexArray = matchedArray.map((x) => x.GeneFileIndex);
    var GeneUnMatched = this.state.GeneFile.filter(
      (x, index) => !GeneIndexArray.includes(index)
    );
    console.log(OrdersUnMatched, GeneUnMatched);
    console.log(matchedArray);
    this.setState({
      dateErrorMeaage: dateErrorMeaage,
      verification: flag,
      buttonPressed: true,
      errorArray: errorArray,
      OrdersUnMatched:
        OrdersUnMatched && OrdersUnMatched.length !== 0
          ? OrdersUnMatched
          : null,
      GeneUnMatched:
        GeneUnMatched && GeneUnMatched.length !== 0 ? GeneUnMatched : null,
      matchedArray:
        matchedArray && matchedArray.length !== 0 ? matchedArray : null,
      SuccessMessage:
        OrdersUnMatched.length === 0 &&
        GeneUnMatched.length === 0 &&
        !errorArray
          ? "success"
          : "fail",
    });
  };

  clearModal = () => {
    document.getElementById("ParcelFile1").value = null;
    document.getElementById("ParcelFile2").value = null;
    this.setState({
      uploadedFile: [],
      uploadedFile2: [],
      matchedArray: null,
      hasUploadedFile: false,
      buttonPressed: false,
      OrdersFile: null,
      OrdersUnMatched: null,
      GeneUnMatched: null,
      GeneFile: null,
      dateErrorMeaage: null,
      verification: false,
      errorArray: null,
    });
  };

  render() {
    if (this.state.Well) {
      return (
        <div className="popup" style={{ overflow: "scroll", lineHeight: 1.2 }}>
          <div
            className="popup_inner"
            style={{ top: "8%", width: "850px", right: "8%", left: "8%" }}
          >
            <div className="card card2" style={{ backgroundColor: "#f4f2f2" }}>
              <h1
                className="Titleidheading secondaryColor"
                style={{ margin: 0, borderBottom: "2px solid #ccc" }}
              >
                {this.props.text}
              </h1>

              <i
                id="x"
                className="material-icons"
                onClick={this.props.closePopup}
                style={{
                  height: "30px",
                  paddingTop: "15px",
                  lineHeight: "0",
                  top: "3px",
                }}
              >
                close
              </i>

              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  minHeight: "75px",
                  borderBottom: "1px solid #336EBD",
                }}
              >
                <label
                  htmlFor="ParcelFile1"
                  className="fileColor"
                  style={{ marginBottom: "auto", marginTop: "auto" }}
                >
                  <div
                    style={{ justifyContent: "center" }}
                    className="themeButton"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading
                      ? "Uploading..."
                      : "Attach Orders JSON File"}
                  </div>
                </label>
                <input
                  id="ParcelFile1"
                  name="file"
                  type="file"
                  accept=".JSON"
                  onChange={(e) => this.grabFile2("Instrument", e)}
                  style={{
                    opacity: "0",
                    position: "absolute",
                    marginLeft: "-6%",
                    width: "30px",
                  }}
                  required
                />
                {this.state.uploadedFile.length !== 0 &&
                  this.state.uploadedFile.map((x, index) => (
                    <div
                      key={index}
                      style={{
                        margin: "auto",
                        paddingLeft: "10px",
                        paddingTop: "5px",
                        width: "70%",
                        display: "flex",
                      }}
                    >
                      <img
                        src={closeIcon}
                        style={{
                          marginLeft: "1.5%",
                          marginTop: "1.6%",
                          position: "absolute",
                          background: "white",
                        }}
                        onClick={() => this.removeFile2("ParcelFile", x)}
                        className="fileDelete"
                      ></img>
                      <a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                          <polyline points="13 2 13 9 20 9"></polyline>
                        </svg>
                      </a>
                      <p style={{ margin: 0, padding: "5px" }}>{x.file.name}</p>
                    </div>
                  ))}
              </div>

              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  minHeight: "75px",
                  borderBottom: "1px solid #336EBD",
                }}
              >
                <label
                  htmlFor="ParcelFile2"
                  className="fileColor"
                  style={{ marginBottom: "auto", marginTop: "auto" }}
                >
                  <div
                    style={{ justifyContent: "center" }}
                    className="themeButton"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading
                      ? "Uploading..."
                      : "Attach Gene Matrix JSON File"}
                  </div>
                </label>
                <input
                  id="ParcelFile2"
                  name="file"
                  type="file"
                  accept=".JSON"
                  onChange={(e) => this.grabFile("Instrument", e)}
                  style={{
                    opacity: "0",
                    position: "absolute",
                    marginLeft: "-6%",
                    width: "30px",
                  }}
                  required
                />
                {this.state.uploadedFile2.length !== 0 &&
                  this.state.uploadedFile2.map((x, index) => (
                    <div
                      key={index}
                      style={{
                        margin: "auto",
                        paddingLeft: "10px",
                        paddingTop: "5px",
                        width: "70%",
                        display: "flex",
                      }}
                    >
                      <img
                        src={closeIcon}
                        style={{
                          marginLeft: "1.5%",
                          marginTop: "1.6%",
                          position: "absolute",
                          background: "white",
                        }}
                        onClick={() => this.removeFile("ParcelFile", x)}
                        className="fileDelete"
                      ></img>
                      <a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                          <polyline points="13 2 13 9 20 9"></polyline>
                        </svg>
                      </a>
                      <p style={{ margin: 0, padding: "5px" }}>{x.file.name}</p>
                    </div>
                  ))}
              </div>
              {this.state.GeneFile && this.state.OrdersFile && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "auto",
                    marginTop: "auto",
                    width: "100%",
                    paddingTop: "15px",
                  }}
                >
                  {this.state.verification &&
                  !this.state.OrdersUnMatched &&
                  !this.state.GeneUnMatched ? (
                    <button
                      style={{
                        width: "150px",
                        height: "29px",
                        justifyContent: "center",
                      }}
                      className="themeButtonBlue"
                      onClick={(e) => this.onSubmit(e)}
                      disabled={this.state.isLoading}
                    >
                      Import
                    </button>
                  ) : (
                    <button
                      style={{
                        width: "150px",
                        height: "29px",
                        justifyContent: "center",
                      }}
                      className="themeButton"
                      onClick={(e) => this.verificationCheck(e)}
                      disabled={this.state.isLoading}
                    >
                      Verify
                    </button>
                  )}
                  {this.state.buttonPressed ? (
                    <button
                      style={{
                        width: "150px",
                        height: "29px",
                        justifyContent: "center",
                        marginLeft: "8px",
                      }}
                      className="themeButton"
                      onClick={() => this.clearModal()}
                      disabled={this.state.isLoading}
                    >
                      Clear
                    </button>
                  ) : null}
                </div>
              )}

              {this.state.buttonPressed ? (
                <div
                  style={
                    this.state.verification
                      ? {
                          textAlign: "center",
                          paddingTop: "20px",
                          fontWeight: "bold",
                          color: "green",
                        }
                      : {
                          textAlign: "center",
                          paddingTop: "20px",
                          fontWeight: "bold",
                          color: "red",
                        }
                  }
                >
                  {this.state.verification
                    ? "Successfully Verified."
                    : "Verification Failed"}
                </div>
              ) : null}
              {!this.state.verification ? (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "20px",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.errorArray}
                </div>
              ) : null}

              {this.state.dateErrorMeaage ? (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "20px",
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {this.state.dateErrorMeaage}
                </div>
              ) : null}
              {(this.state.OrdersUnMatched &&
                this.state.OrdersUnMatched.length !== 0) ||
              (this.state.GeneUnMatched &&
                this.state.GeneUnMatched.length !== 0) ? (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "20px",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  You Have Un Matched Orders
                </div>
              ) : null}
              {/* <div id='output'></div> */}
              {this.state.GeneFile && this.state.OrdersFile && (
                <Tabs style={{ paddingTop: "24px", display: "grid" }}>
                  <TabList
                    style={{
                      marginBottom: "0px !important",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <Tab
                      style={{
                        color: "#2b388f",
                        border: "1px solid #ccc",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        borderBottom: "none",
                      }}
                    >
                      Orders File
                    </Tab>
                    <Tab
                      style={{
                        color: "#2b388f",
                        border: "1px solid #ccc",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        borderBottom: "none",
                      }}
                    >
                      Gene Expression File
                    </Tab>
                    {this.state.matchedArray &&
                    this.state.SuccessMessage === "success" ? (
                      <Tab
                        style={{
                          color: "#2b388f",
                          border: "1px solid #ccc",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                          borderBottom: "none",
                          color: "red",
                        }}
                      >
                        Matched Orders ({this.state.matchedArray.length})
                      </Tab>
                    ) : null}
                    {this.state.OrdersUnMatched &&
                    this.state.SuccessMessage === "fail" ? (
                      <Tab
                        style={{
                          color: "#2b388f",
                          border: "1px solid #ccc",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                          borderBottom: "none",
                          color: "red",
                        }}
                      >
                        Unmatched Orders ({this.state.OrdersUnMatched.length})
                      </Tab>
                    ) : null}
                    {this.state.GeneUnMatched &&
                    this.state.SuccessMessage === "fail" ? (
                      <Tab
                        style={{
                          color: "#2b388f",
                          border: "1px solid #ccc",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                          borderBottom: "none",
                          color: "red",
                        }}
                      >
                        Unmatched Gene Expression (
                        {this.state.GeneUnMatched.length})
                      </Tab>
                    ) : null}
                  </TabList>
                  <TabPanel>
                    <div>
                      <div
                        className="table1"
                        style={{
                          overflow: "scroll",
                          maxHeight: "400px",
                          maxWidth: "811px",
                        }}
                      >
                        <table className="tablestyle">
                          <tbody>
                            <tr>
                              {this.state.OrdersFile &&
                                Object.keys(this.state.OrdersFile[0]).map(
                                  (x, index) => (
                                    <th
                                      key={index}
                                      style={{ fontSize: ".8vw" }}
                                      style={{ textAlign: "center" }}
                                    >
                                      {x}
                                    </th>
                                  )
                                )}
                            </tr>

                            {this.state.OrdersFile &&
                              orderBy(
                                this.state.OrdersFile,
                                [this.state.orderByProperty],
                                [this.state.orderByDirection]
                              ).map((x, index) => (
                                <tr key={index} className="rows">
                                  {this.state.OrdersFile &&
                                    Object.keys(this.state.OrdersFile[0]).map(
                                      (y, index2) => (
                                        <td
                                          key={index + "_" + index2}
                                          style={{
                                            fontSize: ".7vw",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "160px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {x[y]}
                                        </td>
                                      )
                                    )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      <div
                        className="table1"
                        style={{
                          overflow: "scroll",
                          maxHeight: "400px",
                          maxWidth: "811px",
                        }}
                      >
                        <table className="tablestyle">
                          <tbody>
                            <tr>
                              {this.state.GeneFile &&
                                Object.keys(this.state.GeneFile[0]).map(
                                  (x, index) => (
                                    <th
                                      key={index}
                                      style={{ fontSize: ".8vw" }}
                                      style={{ textAlign: "center" }}
                                    >
                                      {x}
                                    </th>
                                  )
                                )}
                            </tr>

                            {this.state.GeneFile &&
                              orderBy(
                                this.state.GeneFile,
                                [this.state.orderByProperty],
                                [this.state.orderByDirection]
                              ).map((x, index) => (
                                <tr key={index} className="rows">
                                  {this.state.GeneFile &&
                                    Object.keys(this.state.GeneFile[0]).map(
                                      (y, index2) =>
                                        y !== "Variables" ? (
                                          <td
                                            key={index + "_" + index2}
                                            style={{
                                              fontSize: ".7vw",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              maxWidth: "160px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {x[y]}
                                          </td>
                                        ) : (
                                          <td
                                            key={index + "_" + index2}
                                            style={{
                                              fontSize: ".7vw",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              maxWidth: "160px",
                                              textAlign: "center",
                                            }}
                                          >
                                            ...
                                          </td>
                                        )
                                    )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </TabPanel>
                  {this.state.matchedArray &&
                    this.state.SuccessMessage === "success" && (
                      <TabPanel>
                        <div>
                          <div
                            className="table1"
                            style={{
                              overflow: "scroll",
                              maxHeight: "400px",
                              maxWidth: "811px",
                            }}
                          >
                            <table className="tablestyle">
                              <tbody>
                                <tr>
                                  <th
                                    style={{ fontSize: ".8vw" }}
                                    style={{ textAlign: "center" }}
                                  >
                                    File Link ID
                                  </th>
                                </tr>

                                {this.state.matchedArray &&
                                  orderBy(
                                    this.state.matchedArray,
                                    [this.state.orderByProperty],
                                    [this.state.orderByDirection]
                                  ).map((x, index) => (
                                    <tr key={index} className="rows">
                                      <td
                                        style={{
                                          fontSize: ".7vw",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          maxWidth: "160px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {
                                          this.state.OrdersFile[index]
                                            .fileLinkId
                                        }
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </TabPanel>
                    )}
                  {this.state.OrdersUnMatched &&
                    this.state.SuccessMessage === "fail" && (
                      <TabPanel>
                        <div>
                          <div
                            className="table1"
                            style={{
                              overflow: "scroll",
                              maxHeight: "400px",
                              maxWidth: "811px",
                            }}
                          >
                            <table className="tablestyle">
                              <tbody>
                                <tr>
                                  {this.state.OrdersUnMatched &&
                                    Object.keys(
                                      this.state.OrdersUnMatched[0]
                                    ).map((x, index) => (
                                      <th
                                        key={index}
                                        style={{ fontSize: ".8vw" }}
                                        style={{ textAlign: "center" }}
                                      >
                                        {x}
                                      </th>
                                    ))}
                                </tr>

                                {this.state.OrdersUnMatched &&
                                  orderBy(
                                    this.state.OrdersUnMatched,
                                    [this.state.orderByProperty],
                                    [this.state.orderByDirection]
                                  ).map((x, index) => (
                                    <tr key={index} className="rows">
                                      {this.state.OrdersUnMatched &&
                                        Object.keys(
                                          this.state.OrdersUnMatched[0]
                                        ).map((y, index2) => (
                                          <td
                                            key={index + "_" + index2}
                                            style={{
                                              fontSize: ".7vw",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              maxWidth: "160px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {x[y]}
                                          </td>
                                        ))}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </TabPanel>
                    )}
                  {this.state.GeneUnMatched &&
                    this.state.SuccessMessage === "fail" && (
                      <TabPanel>
                        <div>
                          <div
                            className="table1"
                            style={{
                              overflow: "scroll",
                              maxHeight: "400px",
                              maxWidth: "811px",
                            }}
                          >
                            <table className="tablestyle">
                              <tbody>
                                <tr>
                                  {this.state.GeneUnMatched &&
                                    Object.keys(
                                      this.state.GeneUnMatched[0]
                                    ).map((x, index) => (
                                      <th
                                        key={index}
                                        style={{ fontSize: ".8vw" }}
                                        style={{ textAlign: "center" }}
                                      >
                                        {x}
                                      </th>
                                    ))}
                                </tr>

                                {this.state.GeneUnMatched &&
                                  orderBy(
                                    this.state.GeneUnMatched,
                                    [this.state.orderByProperty],
                                    [this.state.orderByDirection]
                                  ).map((x, index) => (
                                    <tr key={index} className="rows">
                                      {this.state.GeneUnMatched &&
                                        Object.keys(
                                          this.state.GeneUnMatched[0]
                                        ).map((y, index2) =>
                                          y !== "Variables" ? (
                                            <td
                                              key={index + "_" + index2}
                                              style={{
                                                fontSize: ".7vw",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                maxWidth: "160px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {x[y]}
                                            </td>
                                          ) : (
                                            <td
                                              key={index + "_" + index2}
                                              style={{
                                                fontSize: ".7vw",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                maxWidth: "160px",
                                                textAlign: "center",
                                              }}
                                            >
                                              ...
                                            </td>
                                          )
                                        )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </TabPanel>
                    )}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="lds-roller" style={{ marginTop: "17%" }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }
  }
}

export default ImportOrdersForm;
