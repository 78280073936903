/* Copyright Qualisure Diagnostics, Inc., 2021
All Rights Reserved
UNPUBLISHED, LICENSED SOFTWARE.

CONFIDENTIAL AND PROPRIETARY INFORMATION
WHICH IS THE PROPERTY OF QUALISURE DIAGNOSTICS, INC.

This page houses the dashboard and cards on the dashboard
*/

import React, { Component } from "react";
import { getAuthenticatedAxios } from "../../util";
import axios from "axios";
import OrdersTile from "./OrdersTile";
import ResultsTile from "./ResultsTile";
import MatchedTile from "./MatchedTile";
import { format as formatDate } from "date-fns";


const logoStyles = {
  marginTop: '50px',
  display: 'flex',
  justifyContent: 'center'
}

const logoImgStyles = {
  maxWidth: '40vw'
}

class Dashboard extends Component {
  state = {
    projects: [],
    uploadedFile: [],
  };

  componentDidMount = async () => {
    document.title = "Dashboard";
    const axios = await getAuthenticatedAxios();
    console.log(formatDate(new Date(), "yyyy-MM-dd HH:mm:ss.SSS"));
    await axios.get(`/api/matches`).then((response) => {
      this.setState({
        TodoCounter: response.data.matches.length,
        ResultsData: response.data.ResultsData,
        Reports: response.data.Reports,
      });
    });
    // window.addEventListener('resize', this.resize)
  };
  resize = () => this.forceUpdate();
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.resize)
  // }

  refreshPage = async () => {
    const axios = await getAuthenticatedAxios();
    console.log(formatDate(new Date(), "yyyy-MM-dd HH:mm:ss.SSS"));
    await axios.get(`/api/matches`).then((response) => {
      this.setState({
        TodoCounter: response.data.matches.length,
        ResultsData: response.data.ResultsData,
        Reports: response.data.Reports,
      });
    });
  };

  render() {
    return (
      <div
        className="dashboard container"
        style={{ width: "100%", marginTop: "2%", paddingTop: "6%" }}
      >
        {this.state.projects ? (
          <div
            className="col"
            style={
              window.innerWidth > 900
                ? {
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap",
                  }
                : {}
            }
          >
            <OrdersTile />
            <MatchedTile
              TodoCounter={this.state.TodoCounter}
              refreshPage={() => this.refreshPage()}
            />
            <ResultsTile />
          </div>
        ) : (
          <div className="lds-roller" style={{ marginTop: "19%" }}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}

         {/* Thyroid_GuidePx logo */}
         <div style={logoStyles}>
          <img style={logoImgStyles} src='/Thyroid_GuidePx_logo.png' />
        </div>
      </div>
    );
  }
}

export default Dashboard;
