import React from 'react'
import { NavLink } from 'react-router-dom'
// import { signOut } from '../../store/actions/authActions'
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";

function SignedInLinks() {

  const state = {
    showModal: false,
    popup2button: false
  }

  // const popupReload = (e) =>{
  //   this.setState({ popup2button: !state.popup2button });
  //   // window.location.reload(false);
  // }
  // const OpenProjectForm = (e) => {
  //   this.setState({ showModal: !state.showModal });
  // }
  const openUpload = () => {
    this.setState({ showModal: !state.showModal });
  }

  const signOut = (instance) => {
    console.log("signOut")
    instance.logoutRedirect({
      onRedirectNavigate: () => !BrowserUtils.isInIframe()
    })
  }

  const { instance } = useMsal();
  // if (window.innerWidth > 900) {
  //   return (
  //     <div>
  //       <ul style={{ display: "flex", margin: "0 auto", flexDirection: "column" }}>
  //         <li><NavLink to='/' style={{ fontSize: "1vw" }}>Home</NavLink></li>
  //         <li><NavLink to='' onClick={() => openUpload()}>Upload | Add</NavLink></li>
  //         {/* <li><button className="themeButton" style={{ fontSize: "1vw" }} onClick={}>Upload</button></li> */}
  //         {/* {this.state.roles && this.state.roles.includes(1) ? <li><NavLink to='/Investors' style={{ fontSize: "1vw" }}>Investors</NavLink></li> : null}

  //           <li><NavLink to='/News' style={{ fontSize: "1vw" }}>{"News & Upcoming Events"}</NavLink></li>
  //           <li><NavLink to='/Terms_Of_Access' style={{ fontSize: "1vw" }}>Terms of Access</NavLink></li>
  //           <li><NavLink to='/Contact' style={{ fontSize: "1vw" }}>Contact</NavLink></li>
  //           <li><a className="colorChange" style={{ fontSize: "1vw", fontWeight: "600" }} onClick={this.props.signOut}>Log Out</a></li> */}
  //         {/* <li><div onClick={() => this.OpenProjectForm()} className="btn btn-floating grey lighten-1">
  //           {this.props.profile.initials}
  //         </div></li> */}
  //       </ul>
  //     </div>
  //   )
  // } else {
  return (
    <div>
      <div class="dropdown">
        <button class="dropbtn fa" style={window.innerWidth > 600 && window.innerWidth < 900 ? { fontSize: "1.5em", marginTop: "17%" } : { fontSize: "1.5em", marginTop: "3%" }}>&#xf0c9;</button>
        <div class="dropdown-content1">
          <NavLink to='/' style={{ fontSize: "1.5vh" }}>Home</NavLink>
          {/* <li><button className="themeButton" style={{ fontSize: "1vw" }} onClick={() => openUpload()}>Upload</button></li> */}
          {/* {this.state.roles && this.state.roles.includes(1) ? <NavLink to='/Investors' style={{ fontSize: "1.5vh" }}>Investors</NavLink> : null}
            
            <NavLink to='/News' style={{ fontSize: "1.5vh" }}>{"News & Upcoming Events"}</NavLink>
            <NavLink to='/Terms_Of_Access' style={{ fontSize: "1.5vh" }}>Terms of Access</NavLink>
            <NavLink to='/Contact' style={{ fontSize: "1.5vh" }}>Contact</NavLink> */}
          <a className="colorChange" style={{ fontSize: "1.5vh", borderTop: "1px solid", fontWeight: "600" }} onClick={() => signOut(instance)}>Log Out</a>
        </div>
      </div>
    </div>
  )
}
// }



export default SignedInLinks
