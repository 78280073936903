import axios from 'axios'
import msal from './msal'

export async function getAuthenticatedAxios(body) {
    
    
    const activeAccount = msal.getActiveAccount();
    const accounts = msal.getAllAccounts();

    if(!activeAccount && accounts.length === 0) {
        throw new Error(`getAuthenticatedAxios used in an unauthenticated context`)
    }

    const request = {
        scopes: ['openid','profile'],
        account: activeAccount || accounts[0]
    }

    const { accessToken } = await msal.acquireTokenSilent(request)

    const instance = axios.create()
    instance.defaults.headers.Authorization = `Bearer ${accessToken}`
    
    return instance
  }