/* Copyright Qualisure Diagnostics, Inc., 2021
All Rights Reserved
UNPUBLISHED, LICENSED SOFTWARE.

CONFIDENTIAL AND PROPRIETARY INFORMATION
WHICH IS THE PROPERTY OF QUALISURE DIAGNOSTICS, INC.

The Display tile for the creating batches System
*/

import React, { Component } from 'react'
import MatchedForm from './MatchedForm'

class MatchedTile extends Component {
    state = {
        openForm: false
    }
    OpenGeneForm = () => {
        if(this.state.openForm){
            this.props.refreshPage()
        }
        this.setState({
            openForm: !this.state.openForm
        })
    }
    render() {
        return (
            <div className="section sectionwidth" >
                {this.props.TodoCounter !== 0 && <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <div className="TodoIcon">{this.props.TodoCounter}</div>
                </div>}
                {this.state.openForm ?
                    <MatchedForm
                        EmployeeSubmittedId={this.props.EmployeeID}
                        text='Create and View Batches'
                        closePopup={this.OpenGeneForm.bind(this)}
                    /> : null}
                <a >
                    <div className="card z-depth-0" onClick={() => this.OpenGeneForm()} style={{cursor: "pointer"}}>
                        <div className="card-content roundedcorner">
                            <div className="appdisplay">
                                <i className="fa fa-stethoscope" style={{fontSize: "xx-large"}}></i>
                                <span className="card-title secondaryColor" style={{paddingLeft: "4px"}}>Create a Batch For Analysis</span>
                            </div>
                            <p className="secondaryColor">Create Report(s) from Batch</p>
                        </div>
                    </div>
                </a>
            </div>

        )
    }
}

export default MatchedTile