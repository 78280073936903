/* Copyright Qualisure Diagnostics, Inc., 2021
All Rights Reserved
UNPUBLISHED, LICENSED SOFTWARE.

CONFIDENTIAL AND PROPRIETARY INFORMATION
WHICH IS THE PROPERTY OF QUALISURE DIAGNOSTICS, INC.

This page is where batches are created and sent to the ML
*/

import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { orderBy } from "lodash";
import { getAuthenticatedAxios } from "../../util";
import {
  format as formatDate,
  addMinutes,
  getUnixTime,
  differenceInYears,
} from "date-fns";
import { PDFDocument } from "pdf-lib";

class MatchedForm extends React.Component {
  state = {
    orderByProperty: "MatchDate",
    orderByDirection: "dec",
    uploadedFile: [],
    batchIDs: [],
    patientResults: [],
    Orders: [],
    MatchedData: [
      {
        PatientName: "-",
        PatientID: "-",
        GeneMatrixKey: "-",
        OrdersKey: "-",
        QualisureID: "-",
        TestRequested: "-",
        SpecimenType: "-",
        AccessionID: "-",
        MatchDate: "-",
        OrderDate: "-",
      },
    ],
    checked: [],
    matchesDeleteInProgress: false,
  };
  componentDidMount = async () => {
    const axios = await getAuthenticatedAxios();
    await axios.get(`/api/matches`).then((response) => {
      console.log(response);
      this.setState({
        MatchedData: response.data.matches,
        results: response.data.matches,
        Orders: response.data.Orders,
        batchIDs: response.data.matches.map((x) => x.MatchID),
      });
    });
  };

  sendToML = async () => {
    this.setState({ isLoading: true });
    // var batches = {
    //     batches: this.state.Genes.filter(x => this.state.results.map(y => y.GeneMatrixKey).includes(x.GeneMatrixKey))
    // }
    const filteredMatches = this.state.MatchedData.filter((x) =>
      this.state.batchIDs.includes(x.MatchID)
    );
    var batches = [];
    const matchedOrdersMap = new Map();
    const ordersKeys = this.state.MatchedData.map((data) => data.OrdersKey);
    this.state.Orders.forEach((order) => {
      // only keep orders which matches with MatchedData
      if (ordersKeys.includes(order.OrdersKey)) {
        matchedOrdersMap.set(order.OrdersKey, {
          TestRequested: order.TestRequested,
          SpecimenType: order.SpecimenType,
          OrdersKey: order.OrdersKey
        });
      }
    });
    for (var i = 0; i < filteredMatches.length; i++) {
      var tempVaribles = JSON.parse(filteredMatches[i].Variables);

      const associatedOrderKey = filteredMatches[i].OrdersKey;
      const ordersData = matchedOrdersMap.get(associatedOrderKey) || {};

      batches.push({
        fileLinkId: filteredMatches[i].fileLinkID,
        labID: filteredMatches[i].LabID,
        QC_lab_status: filteredMatches[i].QC_lab_status,
        QC_lab_error_code: filteredMatches[i].QC_lab_error_code,
        SMconverter_version: filteredMatches[i].SMconverter_version,
        TestRequested: ordersData.TestRequested || "",
        SpecimenType: ordersData.SpecimenType || "",
        OrdersKey: ordersData.OrdersKey || '',
        ...tempVaribles,
      });
    }
    batches = { test_data: batches };

    console.log(batches);

    const axios = await getAuthenticatedAxios();
    await axios
      .post("/api/sendBatch", batches)
      .then(async (result) => {
        var mlResults = result.data.batch.body;
        for (var j = 0; j < mlResults.length; j++) {
          var tempOrders = {
            file: `PDFTemplate/${mlResults[j].Report_template_name}.pdf`,
          };

          await axios
            .post("/api/getPDFTemplate", tempOrders)
            .then(async (result) => {
              await this.readFile(result.data.sasUrl, mlResults[j]);
            })
            .catch((err) => {
              alert("There was an error!: " + err);
              return;
            });
        }

        console.log(this.state.patientResults);
      })
      .catch((err) => {
        console.log("send bactch error", err);
        alert("There was an error!: " + err);
      });

    setTimeout(async () => {
      await axios
        .get("api/reloadReports")
        .then((response2) => {
          var tempPatientReports = response2.data.Reports.filter((yy) =>
            this.state.patientResults.includes(yy.ResultsKey)
          );

          this.setState({
            Reports: tempPatientReports,
            MatchedData: response2.data.matches,
            results: response2.data.matches,
            Orders: response2.data.Orders,
            patientResults: [],
            batchIDs: response2.data.matches.map((x) => x.MatchID),
            showReportsTab: true,
            isLoading: false,
          });
        })
        .catch((err) => console.log(err));
    }, 3000);
    //set state and tab

    return;
  };

  readFile = async (e, resultData) => {
    await fetch(e)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((blob) => {
        // Here's where you get access to the blob
        // And you can use it for whatever you want
        // Like calling ref().put(blob)

        // Here, I use it to make an image appear on the page
        // let objectURL = URL.createObjectURL(blob);
        var reader = new FileReader();
        // To change the content of a field, three things must be done; - change the text of the field, - change the length of the content field, - change the cross table reference
        reader.onload = async (aEvent) => {
        
          const filteredData = this.state.Orders.filter(
            (x) =>
            x.OrdersKey === resultData.OrdersKey &&  
            x.FileLinkID === resultData.fileLinkID &&
              x.LabID === resultData.labID
          )[0]
          var fileData = {
            ...filteredData,
            ...resultData,
            fileName:
              resultData.Report_template_name +
              "_" +
              resultData.fileLinkID +
              "_" +
              getUnixTime(new Date()) +
              ".pdf",
            MatchID: this.state.MatchedData.filter(
              (y) =>
                y.fileLinkID === resultData.fileLinkID &&
                y.LabID === resultData.labID
            )[0].MatchID,
          };
          console.log(
            resultData.Report_template_name +
              "_" +
              resultData.fileLinkID +
              "_" +
              getUnixTime(new Date()) +
              ".pdf"
          );
          const pdfDoc = await PDFDocument.load(aEvent.target.result);

          // Get the form containing all the fields
          const form = pdfDoc.getForm();
          const fields = form.getFields();
          fields.forEach((field) => {
            const name = field.getName();
            // Get all fields in the PDF by their names
            const nameField = form.getTextField(name);

            // Fill in the basic info fields
            var fieldValue = fileData[name] ? fileData[name].toString() : name;
            if (
              name.toLocaleLowerCase().includes("date") &&
              name !== "CurrentDate"
            ) {
              var newDate = formatDate(
                addMinutes(
                  new Date(fieldValue),
                  new Date(fieldValue).getTimezoneOffset()
                ),
                "MMM dd, yyyy"
              );
              nameField.setText(newDate);
            } else if (name === "Age" || name === 'PatientAge') {
              var newAge = differenceInYears(
                new Date(fileData.SpecimenCollectionDate),
                new Date(fileData.PatientDateofBirth)
              );
              nameField.setText(newAge.toString());
            } else if (name === "CurrentDate") {
              var newDate = formatDate(new Date(), "MMM dd, yyyy");
              nameField.setText(newDate);
            } else if (name === 'clinicalsynopsis' && fileData['patientclinicalsynopsis']) {
              const value = fileData['patientclinicalsynopsis']
              nameField.setText(value)

            } else {
              nameField.setText(fieldValue);
            }
          });

          // Serialize the PDFDocument to bytes (a Uint8Array)
          const pdfBytes = await pdfDoc.save();

          var modifiedPdf = new Blob([pdfBytes], { type: "application/pdf" });

          const data = new FormData();
          data.append("file", modifiedPdf);
          data.append("patientData", JSON.stringify(fileData));

          const axios = await getAuthenticatedAxios();
          await axios
            .post("/api/addPatientResult", data)
            .then((response2) => {
              var tempPatientResults = this.state.patientResults;
              tempPatientResults.push(response2.data.result[0].ResultsKey);
              this.setState({
                patientResults: tempPatientResults,
              });
            })
            .catch((err) => console.log(err));
        };
        reader.readAsDataURL(blob);
      });
  };

  addToBatch = (e) => {
    const { value, checked } = e.target;
    var tempArray = this.state.batchIDs;
    if (checked) {
      tempArray.push(parseInt(value, 10));
      this.setState({
        batchIDs: tempArray,
      });
    } else {
      tempArray = tempArray.filter((x) => x !== parseInt(value, 10));
      this.setState({
        batchIDs: tempArray,
      });
    }
  };

  openLink = async (filename, LabID) => {
    var tempOrders = { file: filename, labID: LabID };
    const axios = await getAuthenticatedAxios();
    await axios
      .post("/api/getPDFReport", tempOrders)
      .then(async (result) => {
        // console.log(result.data.sasUrl)
        window.open(result.data.sasUrl, "_blank");
      })
      .catch((err) => {
        alert("There was an error!: " + err);
        return;
      });
  };

  handleChecked = (event) => {
    const { checked, value } = event.target;
    let checkedItems = [...this.state.checked];
    if (checked & !checkedItems.includes(Number(value))) {
      checkedItems.push(Number(value));
      this.setState((prev) => ({
        ...prev,
        checked: checkedItems,
      }));
      return;
    }

    checkedItems = checkedItems.filter((id) => id !== Number(value));
    this.setState((prev) => ({
      ...prev,
      checked: checkedItems,
    }));
  };

  handleDeleteCheckedItems = async () => {
    if (this.state.matchesDeleteInProgress) return
    this.setState((prev) => ({
      ...prev,
      matchesDeleteInProgress: true,
    }));
    const ids = this.state.checked;
    const axios = await getAuthenticatedAxios();
    await axios
      .post("/api/remove/matches", { ids })
      .then(() => {
     
        this.setState((prev) => ({
          ...prev,
          results: this.state.results.filter(
            (match) => !this.state.checked.includes(match.MatchID)
          ),
          matchesDeleteInProgress: false,
          MatchedData: this.state.MatchedData.filter(data => !this.state.checked.includes(data.MatchID)),
          checked: [],
        }));
      })
      .catch((error) => {
        console.log("unable to remove matches", error);
        this.setState(prev => ({
            ...prev,
            matchesDeleteInProgress: false
        }))
      });
  };

  render() {
    if (this.state.MatchedData) {
      return (
        <div className="popup" style={{ overflow: "scroll", lineHeight: 1.2 }}>
          <div
            className="popup_inner"
            style={{ top: "8%", right: "8%", left: "8%" }}
          >
            <div className="card card2" style={{ backgroundColor: "#f4f2f2" }}>
              <h1
                className="Titleidheading secondaryColor"
                style={{ margin: 0, borderBottom: "2px solid #ccc" }}
              >
                {this.props.text}
              </h1>

              <i
                id="x"
                className="material-icons"
                onClick={this.props.closePopup}
                style={{
                  height: "30px",
                  paddingTop: "15px",
                  lineHeight: "0",
                  top: "3px",
                }}
              >
                close
              </i>

              <Tabs style={{ paddingTop: "24px", display: "grid" }}>
                <TabList style={{ marginBottom: "0px !important" }}>
                  {this.state.showReportsTab ? (
                    <Tab
                      style={{
                        color: "#2b388f",
                        border: "1px solid #ccc",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        borderBottom: "none",
                      }}
                    >
                      Reports
                    </Tab>
                  ) : null}
                  <Tab
                    style={{
                      color: "#2b388f",
                      border: "1px solid #ccc",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      borderBottom: "none",
                    }}
                  >
                    Batches
                  </Tab>
                </TabList>
                {this.state.showReportsTab ? (
                  <TabPanel>
                    <div>
                      <div
                        className="table1"
                        style={{ overflow: "auto", maxHeight: "400px" }}
                      >
                        <table
                          style={{
                            borderLeft: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            borderBottom: "1px solid #ccc",
                          }}
                        >
                          <tbody>
                            <tr>
                              <th colSpan="8" className="tabletitle topbar">
                                <div
                                  style={{
                                    background: "white",
                                    minHeight: "58px",
                                    display: "flex",
                                  }}
                                >
                                  <button
                                    className="themeButton"
                                    style={{ margin: "auto" }}
                                    onClick={this.props.closePopup}
                                  >
                                    Finish
                                  </button>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() =>
                                  this.onOrderByProperty("PatientName")
                                }
                                style={{
                                  textAlign: "center",
                                  color: "#2b388f",
                                }}
                              >
                                Report Date
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() => this.onOrderByProperty("LabID")}
                                style={{
                                  textAlign: "center",
                                  color: "#2b388f",
                                }}
                              >
                                LabID
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() =>
                                  this.onOrderByProperty("FileLinkID")
                                }
                                style={{
                                  textAlign: "center",
                                  color: "#2b388f",
                                }}
                              >
                                FileLinkID
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() =>
                                  this.onOrderByProperty("PatientFirstName")
                                }
                                style={{
                                  textAlign: "center",
                                  color: "#2b388f",
                                }}
                              >
                                Name
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() =>
                                  this.onOrderByProperty("PatientSex")
                                }
                                style={{
                                  textAlign: "center",
                                  color: "#2b388f",
                                }}
                              >
                                Sex
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() =>
                                  this.onOrderByProperty("PatientDateofBirth")
                                }
                                style={{
                                  textAlign: "center",
                                  color: "#2b388f",
                                }}
                              >
                                DOB
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() =>
                                  this.onOrderByProperty("AccessionID")
                                }
                                style={{
                                  textAlign: "center",
                                  color: "#2b388f",
                                }}
                              >
                                Accession #
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() =>
                                  this.onOrderByProperty("ResultID")
                                }
                                style={{
                                  textAlign: "center",
                                  color: "#2b388f",
                                }}
                              >
                                ResultID
                              </th>
                              <th
                                style={{ fontSize: ".8vw" }}
                                onClick={() =>
                                  this.onOrderByProperty("Filename")
                                }
                                style={{
                                  textAlign: "center",
                                  color: "#2b388f",
                                }}
                              >
                                Report
                              </th>
                            </tr>

                            {this.state.Reports &&
                              this.state.Reports.map((x) => (
                                <tr
                                  className="rows"
                                  key={x.ResultsKey}
                                  style={{ background: "#f3f3f3" }}
                                >
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.ReportDate}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.LabID}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.FileLinkID}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.PatientFirstName +
                                      " " +
                                      x.PatientLastName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.PatientSex}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.PatientDateofBirth}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.AccessionID}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {x.ResultID}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: ".7vw",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "160px",
                                      textAlign: "center",
                                    }}
                                    onClick={() =>
                                      this.openLink(x.Filename, x.LabID)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                      <polyline points="13 2 13 9 20 9"></polyline>
                                    </svg>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </TabPanel>
                ) : null}
                <TabPanel
                  style={{
                    borderLeft: "1px solid #ccc",
                    borderRight: "1px solid #ccc",
                  }}
                >
                  <div>
                    <div
                      className="table1"
                      style={{ overflow: "auto", maxHeight: "400px" }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th colSpan="8" className="tabletitle topbar">
                              <div
                                style={{
                                  background: "white",
                                  minHeight: "58px",
                                  display: "flex",
                                }}
                              >
                                {this.state.results &&
                                this.state.results.length !== 0 ? (
                                  <button
                                    className="themeButton"
                                    style={{ margin: "auto", background: this.state.checked.length? 'red': null }}
                                    onClick={() => {
                                        if (this.state.checked.length) {
                                            this.handleDeleteCheckedItems()
                                            return
                                        }
                                        this.sendToML()
                                    }}
                                    disabled={this.state.isLoading}
                                  >
                                    {this.state.checked.length
                                      ? this.state.matchesDeleteInProgress
                                        ? "Deleting..."
                                        : "Delete Selected"
                                      : this.state.isLoading
                                      ? "Submitting..."
                                      : "Send Batch to Decoder"}
                                    {/* {this.state.isLoading
                                      ? "Submitting..."
                                      : "Send Batch to Decoder"} */}
                                  </button>
                                ) : (
                                  <p style={{ margin: "auto" }}>
                                    {this.state.results &&
                                    this.state.results.length === 0
                                      ? "No Batches"
                                      : "Loading Batch..."}
                                  </p>
                                )}
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th
                              style={{ fontSize: ".8vw" }}
                              style={{ textAlign: "center", color: "#2b388f",position: 'relative', top: '10px' }}
                            >
                             
                              Select <br />
                              <span style={{fontSize: '14px', fontWeight: 'normal'}}>(to delete)</span>
                            </th>
                            <th
                              style={{ fontSize: ".8vw" }}
                              onClick={() =>
                                this.onOrderByProperty("PatientID")
                              }
                              style={{ textAlign: "center", color: "#2b388f" }}
                            >
                              FileLinkID
                            </th>
                            <th
                              style={{ fontSize: ".8vw" }}
                              onClick={() =>
                                this.onOrderByProperty("MatchedDate")
                              }
                              style={{ textAlign: "center", color: "#2b388f" }}
                            >
                              MatchedDate
                            </th>
                            <th
                              style={{ fontSize: ".8vw" }}
                              onClick={() => this.onOrderByProperty("LabID")}
                              style={{ textAlign: "center", color: "#2b388f" }}
                            >
                              LabID
                            </th>
                          </tr>

                          {this.state.results &&
                            orderBy(
                              this.state.results,
                              [this.state.orderByProperty],
                              [this.state.orderByDirection]
                            ).map((x, index) => (
                              <tr
                                key={index}
                                className="rows"
                                style={{ background: "#f3f3f3" }}
                              >
                                <td
                                  style={{
                                    fontSize: ".7vw",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    maxWidth: "160px",
                                    textAlign: "center",
                                  }}
                                >
                                  <input
                                    style={{
                                      opacity: 1,
                                      cursor: "pointer",
                                      marginLeft: "-10px",
                                      marginTop: "-10px",
                                      width: "18px",
                                      height: "18px",
                                      position: "static",
                                      pointerEvents: "unset",
                                    }}
                                    onChange={this.handleChecked}
                                    checked={this.state.checked.includes(
                                      x.MatchID
                                    )}
                                    type="checkbox"
                                    value={x.MatchID}
                                    id="checkBatch"
                                    name="checkBatch"
                                  />
                                </td>
                                <td
                                  style={{
                                    fontSize: ".7vw",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    maxWidth: "160px",
                                    textAlign: "center",
                                  }}
                                >
                                  {x.fileLinkID}
                                </td>
                                <td
                                  style={{
                                    fontSize: ".7vw",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    maxWidth: "160px",
                                    textAlign: "center",
                                  }}
                                >
                                  {x.MatchedDate}
                                </td>
                                <td
                                  style={{
                                    fontSize: ".7vw",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    maxWidth: "160px",
                                    textAlign: "center",
                                  }}
                                >
                                  {x.LabID}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="lds-roller" style={{ marginTop: "17%" }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }
  }
}

export default MatchedForm;
